import React, { useState } from 'react'
import NavBar from '../components/NavBar'
import TopBar from '../components/TopBar'
import { collection, getDocs, query,updateDoc,doc} from 'firebase/firestore';
import { db } from '../firebase';
import { Badge, Box, Button, Chip, CircularProgress, Switch, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
function Commande() {
    const [isLoading,setIsLoading] = useState(true);
    const [documents,setDocuments] = useState([]);
    const [checked,setChecked] = useState(false);
    React.useEffect(() => {
        getCommande();
    }, []);

    const getCommande = async()=> {
        const docRef = collection(db, 'commands');
        const q = query(docRef);
        const querySnapshot = await getDocs(q);
        const dataArr = [];
        querySnapshot.forEach((res) => {
            const { address,createdAt,id,isPaied,products,ref,transactionId,total, delivery,user} = res.data();
            dataArr.push({
                ID: res.id,
                address,
                createdAt,
                id,
                isPaied,
                products,
                ref,
                transactionId,
                total,
                delivery,
                user
            });
        });
        setDocuments(dataArr)
        setIsLoading(false);
    }
    const filteredDocuments = documents;            /* documents.filter(doc => doc.isPaied === true); */
    const handleDeleteProd =()=>
    {
        alert('produits supprimer de la commande ')
    }
    const sortByDelivery = () => {
        let sortedOrders = [...documents].sort((a, b) => {
          if (a.delivery === b.delivery) {
            return 0;
          }
          else if (a.delivery) {
            return 1;
          }
          else {
            return -1;
          }
        });
        setDocuments(sortedOrders);
      }

      const modificateur = async (id) => {
        await updateDoc(doc(db, 'commands', `${id}`), {
          delivery: !checked
        });
        setChecked(!checked);
        getCommande();
        sortByDelivery();
      };

    if (isLoading) {
        return(
            <div>
            <TopBar/>
            <NavBar/>
            <div className='loadinggg'>
            <Box>
            <CircularProgress />
            </Box>
            </div>
            </div>
        )

    } else {   
        return (
            <div>
                <TopBar/>
                <NavBar/>
                <div className='box-tableau'>
                    <div className='tableau'>
                        
                                        <TableContainer component={Paper}>
  <Button variant='contained' onClick={()=> sortByDelivery()}>trier</Button>
  <Table sx={{ minWidth: 700 }} aria-label="customized table">
    <TableHead className='entete-table'>
      <TableRow>
        <StyledTableCell align="center">adresse</StyledTableCell>
        <StyledTableCell align="center">clients</StyledTableCell>
        <StyledTableCell align="center">numero de telephone</StyledTableCell>
        <StyledTableCell align="center">reference commande</StyledTableCell>
        <StyledTableCell align="center">reference transaction</StyledTableCell>
        <StyledTableCell align="center">Produits</StyledTableCell>
        <StyledTableCell align="center">Prix total</StyledTableCell>
        <StyledTableCell align="center">livraison</StyledTableCell>
        <StyledTableCell align="center">liver ?</StyledTableCell>
      </TableRow>
    </TableHead>
    <TableBody className='form-table'>
      {filteredDocuments.map((row) => (
        <StyledTableRow key={row.ID}>
          <StyledTableCell align="center">{row.address.country},{row.address.city},{row.address.street},{row.address.postalCode}</StyledTableCell>
          <StyledTableCell align="center">{row.user.firstName} {row.user.lastName}</StyledTableCell>
          <StyledTableCell align="center">{row.user.phone}</StyledTableCell>
          <StyledTableCell align="center">{row.ref}</StyledTableCell>
          <StyledTableCell align="center">{row.transactionId}</StyledTableCell>
          <StyledTableCell align="center">{row.products.map((prod)=>(
            <Badge key={prod.id} badgeContent={prod.cartNumber} color='primary'  anchorOrigin={{vertical: 'top',horizontal: 'left'}}>
              <Chip label={prod.name} variant="outlined" onDelete={()=>handleDeleteProd()} />
            </Badge>
          ))}</StyledTableCell>
          <StyledTableCell align="center">{row.total}</StyledTableCell>
          <StyledTableCell align="center">{row.delivery ?   <h2>🟢</h2> :<h2>🔴</h2>}</StyledTableCell>
          <StyledTableCell align="center">
            <Switch checked={row.delivery}  onClick={()=> {modificateur(row.ID); setChecked(!row.delivery); }}
            inputProps={{ 'aria-label': 'controlled' }} />
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

                    </div>
                                    </div>
                
            </div>
        )
    }
}

export default Commande
