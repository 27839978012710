import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import TopBar from "../components/TopBar";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebase";
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const SousCat = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    getSouscat();
  }, []);
  const getSouscat = async () => {
    const docRef = collection(db, "subCategories");
    const q = query(docRef);
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach((res) => {
      const { name, idCategory } = res.data();
      dataArr.push({
        id: res.id,
        name,
        idCategory,
      });
    });
    setDocuments(dataArr);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div>
        <TopBar />
        <NavBar />
        <div className="loadinggg">
          <Box>
            <CircularProgress />
          </Box>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div>
        <TopBar />
        <NavBar />
        <div className="box-tableau">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Nom</StyledTableCell>
                  <StyledTableCell align="center">category Id</StyledTableCell>
                  <StyledTableCell align="center">image</StyledTableCell>
                  <StyledTableCell align="center">Nom Category</StyledTableCell>
                  <StyledTableCell align="center">Mofifier</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.idCategory}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.image}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.idCategory === "2L8RJdmI9lVNM6q6uEqN" ? (
                        <h4>Enfant</h4>
                      ) : row.idCategory === "ERtA7ko13paqIB9JdW4y" ? (
                        <h4>Femmes</h4>
                      ) : row.idCategory === "Hc3HhiQJco7uKoY2kWvM" ? (
                        <h4>Produits de cheveux</h4>
                      ) : row.idCategory === "MW2pN1JYcg2SwX62X6Bb" ? (
                        <h4>Accessoires de cheveux</h4>
                      ) : row.idCategory === "UnV1vWSdkR2bPNpZVUQY" ? (
                        <h4>Produits de peau</h4>
                      ) : row.idCategory === "agG1Nyvp0Mk3zWFI1aFm" ? (
                        <h4>Hommes</h4>
                      ) : null}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button variant="contained"> Mofifier </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default SousCat;
