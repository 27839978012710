import React, { useState } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import Loader from "./Loader";
import { Link } from "react-router-dom";

function NavBar() {
  const [loading, setLoading] = useState(false);
  const logOut = () => {
    setLoading(true);
    setTimeout(() => {
      signOut(auth);
      {
        <ListItemButton href="/">
        <ListItemText primary="Accueil" />
        </ListItemButton>
      }
    }, 1000);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="navbar">
      <List
        sx={{ width: "100%", maxWidth: 360 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Menu
          </ListSubheader>
        }
      >
        <ListItemButton>
          <Link to="/"><ListItemText primary="Accueil" /></Link>
        </ListItemButton>
        <ListItemButton>
        <Link to="/articles"><ListItemText primary="Articles" /></Link>
        </ListItemButton>
        <ListItemButton>
        <Link to="/commande"><ListItemText primary="commandes" /></Link>
        </ListItemButton>
        <ListItemButton>
        <Link to="/clients"><ListItemText primary="Comptes Clients" /></Link>
        </ListItemButton>
        <ListItemButton>
        <Link to="/sous-category"><ListItemText primary="Sous Category" /></Link>
        </ListItemButton>
        <ListItemButton onClick={logOut}>
          <ListItemText primary="Se déconnecter" />
        </ListItemButton>
      </List>
    </div>
  );
}

export default NavBar;
