import "./App.css";
import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Commande from "./pages/Commande";
import Articles from "./pages/Articles";
import Clients from "./pages/Clients";
import SousCat from "./pages/SousCat";
import Login from "./pages/Login";
import { Offline, Online } from "react-detect-offline";
import internetError from "./assets/internetError.png";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import Flask from "./pages/Flask";

function App() {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(true);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    const unsusbcribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
      setLoading(false);
    });
    return () => unsusbcribe();
  };

  if (loading) return null;

  if (!authenticated) {
    return (
      <div className="App">
        <Online>
          <Routes>
            <Route path="/" element={<Login />} />
          </Routes>
        </Online>
        <Offline>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img src={internetError} width="50%" />
            <p className="mt-4">
              Aucune connexion internet détectée, nous rechargerons
              automatiquement cette page dès que nous en détecterons une.
            </p>
          </div>
        </Offline>
      </div>
    );
  }

  return (
    <div className="App">
      <Online>
        <Routes>
          <Route path="/*" element={<Home />} />
          <Route path="/commande" element={<Commande />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/sous-category" element={<SousCat />} />
          <Route path="/flask" element={<Flask/>} />

        </Routes>
      </Online>
      <Offline>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={internetError} width="30%" />
          <p className="mt-4">
            Aucune connexion internet détectée, nous rechargerons
            automatiquement cette page dès que nous en détecterons une.
          </p>
        </div>
      </Offline>
    </div>
  );
}

export default App;
