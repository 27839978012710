import React from 'react'
import { Heading } from '@chakra-ui/react'
function TopBar() {
    return (
        <div className='topbar'>
            <div className='topbar-heading'>
            <Heading as='h2' size='3xl' noOfLines={1}>
                    Tableau de bord
                </Heading>
            </div>
        </div>
    )
}

export default TopBar
