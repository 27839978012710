import React from "react";

export default function Loader() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex:10
      }}
      className="bg-black loader d-flex flex-column justify-content-center align-items-center"
    >
      <img
        className="mb-4"
        src={require("../assets/logo.png")}
        alt="logo"
        width="150"
      />
      <p className="h3 text-white">Chargement...</p>
    </div>
  );
}
