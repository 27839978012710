import React, { useState } from 'react'
import NavBar from '../components/NavBar'
import TopBar from '../components/TopBar'
import { collection,getCountFromServer,getDocs, query, where} from 'firebase/firestore';
import { db } from '../firebase';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
function Home() {
    const [sizeProducts,setSizeProducts] = useState(0);
    const [sizeCommande,setSizeCommande] = useState(0);
    const [sizeUsers,setSizeUsers] = useState(0);
    const [isLoading,setIsLoading] = useState(true);
    React.useEffect(() => {
        getSizeproducts();
        getSizeUsers();
        getSizeCommande();
    }, []);

    const getSizeproducts = async()=> {
        const docRef = collection(db, 'products');
        const q = query(docRef);
        const querySnapshot = await getDocs(q);
        setSizeProducts(querySnapshot.size)
        setIsLoading(false);
    }
    const getSizeUsers = async()=> {
        const docRef = collection(db, 'users');
        const q = query(docRef);
        const querySnapshot = await getDocs(q);
        setSizeUsers(querySnapshot.size)
    }
    const getSizeCommande = async() => {
        const coll = collection(db, "commands");
        const query_ = query(coll, where('isPaied', '==', true));
        const snapshot = await getCountFromServer(query_);
        setSizeCommande(snapshot.data().count);
      }

    if (isLoading) {
        return(
            <div>
                <TopBar/>
                <NavBar/>
            <div className='loadinggg'>
            <Box>
            <CircularProgress />
            </Box>
            </div>
            </div>
        )

    } else {   
        return  (
        <div>
            <TopBar/>
            <NavBar/>
            <div className='box-option'>
                        <div className='box-value'>
                            <Link to='/articles'>
                                <h1>Articles : {sizeProducts}</h1>
                            </Link>
                        </div>
                        <div className='box-value'>
                        <Link to='/clients'>
                                 <h1>clients : {sizeUsers}</h1>
                            </Link>
                        </div>
                        <div className='box-value'>
                        <Link to='/commande'>
                            <h1>Commande : {sizeCommande}</h1>
                            </Link>
                        </div>
            </div>
        </div>
    )}
}

export default Home
