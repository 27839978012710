import React, { useState } from "react";
import {
  collection,
  doc,
  getDocs,
  query,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  AvatarGroup,
  Avatar,
  Button,
  Switch,
  Fab,
  Fade,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TopBar from "../components/TopBar";
import NavBar from "../components/NavBar";
import Backdrop from "@mui/material/Backdrop";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Articles() {
  const [name, setName] = useState("");
  const [describe, setDescribe] = useState();
  const [openadd, setOpenadd] = useState(false);
  const handleOpenadd = () => setOpenadd(true);
  const [isLoading, setIsLoading] = useState(true);
  const [price, setPrice] = useState(0);
  const [promoPrice, setPromoPrice] = useState(0);
  const [stock, setStock] = useState(0);
  const [image, setImage] = useState([]);
  const [selectsubcat, setSelectsubcat] = useState("");

  const [checked, setChecked] = React.useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 10,
    borderRadius: 5,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setOpenadd(false);
  };
  const [documents, setDocuments] = React.useState([]);
  const [souscat, setSouscat] = useState([]);
  const Ondelete = async (id) => {
    await deleteDoc(doc(db, "products", id));
    window.location.reload();
  };
  React.useEffect(() => {
    getProducts();
    subcat();
  }, []);
  const ADDdoc = async () => {
    const docRef = await addDoc(collection(db, "products"), {
      name: name,
      describe: describe,
      image: image,
      stock: stock,
      price: price,
      promoPrice: promoPrice,
      isActive: true,
      subcategories: selectsubcat,
    });
    console.log("Document written with ID: ", docRef.id);
    handleClickpop();
    window.location.reload();
  };
  const getProducts = async () => {
    const docRef = collection(db, "products");
    const q = query(docRef);
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach((res) => {
      const { name, image, price, promoPrice, describe, isActive, stock } =
        res.data();
      dataArr.push({
        id: res.id,
        name,
        image,
        price,
        promoPrice,
        describe,
        isActive,
        stock,
      });
    });
    setDocuments(dataArr);
    setIsLoading(false);
  };
  const subcat = async () => {
    const docRef = collection(db, "subCategories");
    const q = query(docRef);
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach((res) => {
      const { name } = res.data();
      dataArr.push({
        id: res.id,
        name,
      });
    });
    setSouscat(dataArr);
  };
  const [openpop, setOpenpop] = React.useState(false);
  const handleClickpop = () => {
    setOpenpop(true);
  };

  const handleClosepop = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenpop(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClosepop}>
        X
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClosepop}
      ></IconButton>
    </React.Fragment>
  );
  const copyImage = (event) => {
    const files = event.target.files;
    const newImageNames = [];
    for (let i = 0; i < files.length; i++) {
      newImageNames.push(
        "https://www.nappylocks.com/database/images/produits/" + files[i].name
      );
    }
    setImage(newImageNames);
  };
  const modificateur = async (id) => {
    console.log("ok");
    await updateDoc(doc(db, "products", `${id}`), {
      isActive: !checked,
    });
    setChecked(!checked);
    getProducts();
  };
  const modificateurArticle = async (id) => {
    console.log("ok");
    await updateDoc(doc(db, "products", `${id}`), {
      name: name,
      describe: describe,
      price: price,
      promoPrice: promoPrice,
      stock: stock,
      subcategories: selectsubcat,
    });
    alert("article modifier");
    window.location.reload();
  };
  if (isLoading) {
    return (
      <div>
        <TopBar />
        <NavBar />
        <div className="loadinggg">
          <Box>
            <CircularProgress />
          </Box>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <TopBar />
        <NavBar />
        <div className="box-tableau">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>image</StyledTableCell>
                  <StyledTableCell align="center">Nom</StyledTableCell>
                  <StyledTableCell align="center">descriptions</StyledTableCell>
                  <StyledTableCell align="center">prix</StyledTableCell>
                  <StyledTableCell align="center">prix Promo</StyledTableCell>
                  <StyledTableCell align="center">Modifier</StyledTableCell>
                  <StyledTableCell align="center">supprimer</StyledTableCell>
                  <StyledTableCell align="center">actif</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      <AvatarGroup max={4}>
                        {row.image.map((image, index) => (
                          <Avatar key={index} alt={image} src={image} />
                        ))}
                      </AvatarGroup>
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.name}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.describe}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.price}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.promoPrice}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Button
                        onClick={() => {
                          handleOpen();
                        }}
                      >
                        Modifier
                      </Button>
                      <Modal
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="keep-mounted-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Mofifier
                            <FormControl
                              sx={{ m: 1, width: "25ch" }}
                              variant="outlined"
                            >
                              <FormHelperText id="outlined-weight-helper-text">
                                Nom
                              </FormHelperText>
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                type="text"
                                aria-describedby="Name-text"
                                onChange={(e) => setName(e.target.value)}
                              />
                            </FormControl>
                            <FormControl
                              sx={{ m: 1, width: "25ch" }}
                              variant="outlined"
                            >
                              <FormHelperText id="outlined-weight-helper-text">
                                description
                              </FormHelperText>
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                type="text"
                                aria-describedby="Name-text"
                                onChange={(e) => setDescribe(e.target.value)}
                              />
                            </FormControl>
                            <FormControl
                              sx={{ m: 1, width: "25ch" }}
                              variant="outlined"
                            >
                              <FormHelperText id="outlined-weight-helper-text">
                                Prix
                              </FormHelperText>
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                type="number"
                                endAdornment={
                                  <InputAdornment position="end">
                                    CFA
                                  </InputAdornment>
                                }
                                aria-describedby="price-number"
                                onChange={(e) =>
                                  setPrice(Number(e.target.value))
                                }
                              />
                            </FormControl>
                            <FormControl
                              sx={{ m: 1, width: "25ch" }}
                              variant="outlined"
                            >
                              <FormHelperText id="outlined-weight-helper-text">
                                Prix Promo
                              </FormHelperText>
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                type="number"
                                endAdornment={
                                  <InputAdornment position="end">
                                    CFA
                                  </InputAdornment>
                                }
                                aria-describedby="price-number"
                                onChange={(e) =>
                                  setPromoPrice(Number(e.target.value))
                                }
                              />
                            </FormControl>
                            <FormControl
                              sx={{ m: 1, width: "25ch" }}
                              variant="outlined"
                            >
                              <FormHelperText id="outlined-weight-helper-text">
                                stock
                              </FormHelperText>
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                type="number"
                                aria-describedby="Name-text"
                                onChange={(e) =>
                                  setStock(Number(e.target.value))
                                }
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Sous Category
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Sous Category"
                                onChange={(e) =>
                                  setSelectsubcat(e.target.value)
                                }
                              >
                                {souscat.map((sub) => (
                                  <MenuItem key={sub.id} value={sub.id}>
                                    {sub.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <Button
                              variant="contained"
                              onClick={() => modificateurArticle(row.id)}
                            >
                              {" "}
                              Modifié
                            </Button>
                          </Typography>
                        </Box>
                      </Modal>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Button onClick={() => Ondelete(row.id)}>
                        supprimer
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Switch
                        checked={row.isActive}
                        onClick={() => modificateur(row.id)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="flottant">
          <Fab
            color="primary"
            aria-label="add"
            size="large"
            onClick={handleOpenadd}
          >
            +
          </Fab>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openadd}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openadd}>
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                creation d'article
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                veillez a bien renseigner les valeurs des champs articles
              </Typography>
              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <FormHelperText id="outlined-weight-helper-text">
                  Nom
                </FormHelperText>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  type="text"
                  aria-describedby="Name-text"
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <FormHelperText id="outlined-weight-helper-text">
                  description
                </FormHelperText>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  type="text"
                  aria-describedby="Name-text"
                  onChange={(e) => setDescribe(e.target.value)}
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <FormHelperText id="outlined-weight-helper-text">
                  Prix
                </FormHelperText>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">CFA</InputAdornment>
                  }
                  aria-describedby="price-number"
                  onChange={(e) => setPrice(Number(e.target.value))}
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <FormHelperText id="outlined-weight-helper-text">
                  Prix Promo
                </FormHelperText>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">CFA</InputAdornment>
                  }
                  aria-describedby="price-number"
                  onChange={(e) => setPromoPrice(Number(e.target.value))}
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <FormHelperText id="outlined-weight-helper-text">
                  stock
                </FormHelperText>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  type="number"
                  aria-describedby="Name-text"
                  onChange={(e) => setStock(Number(e.target.value))}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Sous Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sous Category"
                  onChange={(e) => setSelectsubcat(e.target.value)}
                >
                  {souscat.map((sub) => (
                    <MenuItem key={sub.id} value={sub.id}>
                      {sub.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <FormHelperText id="outlined-weight-helper-text">
                  images
                </FormHelperText>
                <input
                  id="outlined-adornment-weight"
                  type="file"
                  multiple
                  accept="image/jpeg, image/png, image/jpg"
                  aria-describedby="Name-text"
                  onChange={(e) => copyImage(e)}
                />

                <Button variant="contained" onClick={() => ADDdoc()}>
                  Créer
                </Button>
              </FormControl>
              <Snackbar
                open={openpop}
                autoHideDuration={6000}
                onClose={handleClosepop}
                message="Article créer avec succes"
                action={action}
              />
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default Articles;
