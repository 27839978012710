import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";

export default function Login() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const signIn = async (e) => {
    setErrorMessage(false);
    e.preventDefault();
    setLoading(true);
    if (
      email !== process.env.REACT_APP_AUTH_CRED ||
      password !== process.env.REACT_APP_AUTH_CRED
    ) {
      setTimeout(() => {
        setLoading(false);
        setErrorMessage(true);
        return;
      }, 1000);
    }
    await signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage(true);
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className="text-center bg-black"
      style={{ height: "100vh", paddingTop: "10%" }}
    >
      <main className="form-signin w-100 m-auto">
        <form onSubmit={signIn}>
          <img
            style={{ width: 80, height: 80 }}
            src={require("../assets/logo.png")}
            alt="logo"
          />
          <h1 className="h3 mb-3 fw-normal text-white">Connexion</h1>
          {errorMessage && (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              Adresse e-mail ou mot de passe incorrect
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
          <div className="form-floating">
            <input
              name="email"
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <label htmlFor="floatingInput">Adresse email</label>
          </div>
          <div className="form-floating">
            <input
              name="password"
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <label htmlFor="floatingPassword">Mot de passe</label>
          </div>
          <button className="w-100 btn btn-lg btn-primary" type="submit">
            Valider
          </button>
          <p className="mt-5 mb-3 text-white">&copy; 2022 </p>
        </form>
      </main>

      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/js/bootstrap.bundle.min.js"
        crossOrigin="anonymous"
      ></script>
    </div>
  );
}
