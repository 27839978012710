import React, { useEffect,useState } from 'react';
import axios from 'axios';


const Flask = () => {
    const [message, setMessage] = useState('');

    useEffect(() => {
      axios.get('http://nappy.buyenjoy.store/mypythonapp')
        .then(response => setMessage(response.data))
        .catch(error => console.log(error));
    }, []);
  
    return (
      <div>
        <h1>{message}</h1>
      </div>
    );
};

export default Flask;