import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import TopBar from '../components/TopBar';
import { collection, doc, getDocs, query,updateDoc} from 'firebase/firestore';
import { db } from '../firebase';
import { Box, CircularProgress, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const Clients = () => {
    const [isLoading,setIsLoading] = useState(true);
    const [documents,setDocuments] = useState([]);
    React.useEffect(() => {
        getClients();
    }, []);

    const getClients = async()=> {
        const docRef = collection(db, 'users');
        const q = query(docRef);
        const querySnapshot = await getDocs(q);
        const dataArr = [];
        querySnapshot.forEach((res) => {
            const { country, email, firstName, lastName, phone,adress} = res.data();
            dataArr.push({
                id: res.id,
                country,
                email,
                firstName,
                lastName,
                phone,
                adress,
            });
        });
        setDocuments(dataArr)
        setIsLoading(false);
    }



    if (isLoading) {
        return(
            <div>
                <TopBar/>
                <NavBar/>
            <div className='loadinggg'>
            <Box>
            <CircularProgress />
            </Box>
            </div>
            </div>
        )

    } else {   
        return (
                            <div>
                                <TopBar/>
                                <NavBar/>
                                <div className='box-tableau'>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="center">Pays</StyledTableCell>
                                                        <StyledTableCell align="center">email</StyledTableCell>
                                                        <StyledTableCell align="center">Nom</StyledTableCell>
                                                        <StyledTableCell align="center">Prenoms</StyledTableCell>
                                                        <StyledTableCell align="center">telephone</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {documents.map((row) => (
                                                        <StyledTableRow key={row.id}>
                                                            <StyledTableCell align="center">Côte D'ivoire</StyledTableCell>
                                                            <StyledTableCell align="center">{row.email}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.firstName}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.lastName}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.phone}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                            </div>
        )
    }
}


export default Clients;